export const Bio = {
  name: "Lakshan H",
  roles: ["Software Engineer"],
  description:
    "A passionate tech enthusiasts aiming to secure a challenging position in an organization as a Software Development Engineer.\nI am eager to contribute my skills and expertise to a dynamic software engineering team, for the upliftment of the company and also expand my learning, knowledge and skills.",
  resume:
    "https://drive.google.com/file/d/1nwreoCyrtqNNfRu71b6k185e6mfQXl2n/view?usp=sharing",
};

export const skills = [
  {
    name: "Data Structures and Algorithms",
    img: "/skillImgs/dsa.png",
  },
  { name: "C++", img: "/skillImgs/c.png" },
  { name: "Java", img: "/skillImgs/java.png" },
  { name: "HTML", img: "/skillImgs/html.png" },
  { name: "CSS", img: "/skillImgs/css.png" },
  { name: "JavaScript", img: "/skillImgs/js.png" },
  { name: "NodeJS", img: "/skillImgs/nodeJs.png" },
  { name: "ExpressJS", img: "/skillImgs/expressJs.png" },
  { name: "MongoDB", img: "/skillImgs/mongodb.png" },
  { name: "Databases - SQL and NoSQL", img: "/skillImgs/database.png" },
  { name: "TestNG", img: "/skillImgs/java.png" },
  { name: "Cypress", img: "/skillImgs/js.png" },
  { name: "Git", img: "/skillImgs/git.png" },
];

export const experiences = [
  {
    id: 0,
    role: "Software Development in Test, Apprentice",
    company: "Striim, Chennai",
    date: "Dec 2023 - Present",
    desc: "Parallel Execution Optimization:Experimented with parallel execution of test cases to expedite job completion for Integration Tests. While still in the testing phase, initial results indicate a promising 25% reduction in execution time.\nHolistic Test Planning: Crafting test plans for both Front-End and Back-End testing.\nSeamless Transition of Responsibilities: Continued the seamless execution of responsibilities previously mastered during my internship, maintaining a consistent standard of excellence and reliability.",
    skills: ["Java", "TestNG", "Cypress", "Databases", "MessageBus"],
  },
  {
    id: 1,
    role: "Software Development in Test, Intern",
    company: "Striim, Chennai",
    date: "Jan 2023 - Nov 2023",
    desc: "Test Automation Excellence: Automated over 100 test cases within a month using Java(TestNG) for adapters predominantly handling NoSQL databases, particularly Mongo.\nRegression Stabilization Achievement: Successfully reduced random failure rates from 15% to under 3% by strategically refactoring outdated test flows in suites managing message buses, with a primary focus on Kafka.\nInnovative Framework Development: Designed and implemented a framework enabling the execution of a common target adapter against various source adapters and vice versa. This initiative significantly streamlined code, reducing the lines required for a 5-step automation code to run for 4 similar adapters from 5x4 to just 5x1.\n UI Automation Collaboration: Contributed to the UI team by providing support in Cypress automation as needed.",
    skills: ["Java", "TestNG", "Cypress", "Databases", "MessageBus"],
  },
];

export const education = [
  {
    id: 0,
    place: "Coimbatore Institute of Technology, Coimbatore",
    date: "2019 - 2023",
    grade: "8.94 CGPA",
    desc: "Acquired foundational knowledge in Data Structures and Algorithms (DSA), Database Management Syste (DBMS), Computer Networks, Operating Systems, and Object-Oriented Analysis and Design (OOPs).",
    course: "B.E, Computer Science and Engineering",
    skills: [
      "Data Structures and Algorithms",
      "Database Management Systems",
      "Operating Systems",
      "Computer Networks",
      "Object Oriented Design",
    ],
  },
  {
    id: 1,
    place: "Kendriya Vidhyalaya Aruvankadu",
    date: "2018 - 2019",
    grade: "94.2%",
    course: "All India Senior School Certificate Examination",
  },
];

export const projects = [
  {
    id: 0,
    title: "NATours",
    description:
      "Developed NATours, a comprehensive full-stack web application enabling users to explore available tours and seamlessly make bookings. Acquired in-depth knowledge in Backend development, RESTful API design, CRUD operations, and MVC architecture through the project’s implementation. Key Features:(i)Authentication: Implemented user authentication and authorization using JWT, enabling secure login and signup functionality; incorporated admin-level access restrictions for specific actions, enhancing user data protection and system integrity.(ii)Automated Email: Integrated SendGrid for efficient email communication during signup and password recovery. Managed secure payments using Stripe.(iii) Error Handling Excellence: Implemented robust error handling mechanisms tailored to real-world scenarios. In the production environment, users receive only relevant error messages, ensuring a smooth user experience. In the development environment, detailed error information, including stack traces, is provided to facilitate efficient debugging for developers.",
    image: "./projectImgs/naTours.png",
    video: "https://youtu.be/yXYa47wgQlg",
    tags: [
      "Node Js",
      "Express Js",
      "Mongoose",
      "MongoDb",
      "HTML",
      "CSS",
      "JavaScript",
    ],
  },
  {
    id: 1,
    title: "Snake Game",
    description:
      "Developed a simple 2D snake game using C++ and it’s graphics library.",
    image: "./projectImgs/snakeGame.png",
    video: "https://youtu.be/k6etwkJ1kPM",
    tags: ["C++", "Programming", "Problem Solving", "Graphics"],
  },
  {
    id: 2,
    title: "Rat and Maze",
    description:
      "Addressed a data structures challenge (Rat and Maze) through BFS and DFS algorithm implementations, creating a visual using C++ graphics. This deepened my understanding of Backtracking, Recursion and Shortest Path algorithms.",
    image: "./projectImgs/RatAndMaze.png",
    video: "https://youtu.be/z8B285mOuMg",
    tags: ["C++", "Problem Solving", "Graphics", "Algorithms", "BFS", "DFS"],
  },
  {
    id: 3,
    title: "Bankers App",
    description:
      "Created a basic web app showcasing user deposit and withdrawal amounts, enhancing my understanding of fundamental web development concepts.",
    image: "./projectImgs/bankersApp.png",
    video: "https://youtu.be/b-C9mOs19wc",
    tags: ["HTML", "CSS", "JavaScript"],
  },
  {
    id: 4,
    title: "Portfolio",
    description: "The page which you are viewing currently.",
    image: "./projectImgs/portfolio.png",
    tags: ["ReactJS", "CSS", "JavaScript"],
  },
];
