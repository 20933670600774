import React from "react";
import styled from "styled-components";

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Span = styled.span`
  overflow: visible;
  color: ${({ theme }) => theme.text_secondary};
  display: -webkit-box;
  text-align: justify;
  max-width: 100%;
  -webkit-line-clamp: unset;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Card = styled.div`
  width: 800px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 0.3s ease-in-out;
  -webkit-line-clamp: unset;

  @media only screen and (max-width: 800px) {
    padding: 10px;
    gap: 8px;
    width: 300px;
  }

  border: 0.1px solid #306ee8;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Course = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Place = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_primary};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Date = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

// const Skills = styled.div`
//   width: 100%;
//   display: flex;
//   gap: 12px;
//   margin-top: -10px;
// `;

// const ItemWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
// `;

// const Skill = styled.div`
//   font-size: 15px;
//   font-weight: 400;
//   color: ${({ theme }) => theme.text_primary + 99};
//   @media only screen and (max-width: 768px) {
//     font-size: 12px;
//   }
// `;

const EducationCard = ({ education }) => {
  return (
    <Card>
      <Top>
        <Body>
          <Course>{education.course}</Course>
          <Place>{education.place}</Place>
          <Date>{education.date}</Date>
        </Body>
      </Top>
      <Description>
        {education?.grade && <Span>Grade: {education.grade}</Span>}
        {education?.skills && (
          <>
            <br />
            {education.desc}
            {/* <Skills>
              <b>Skills:</b>
              <ItemWrapper>
                {education?.skills?.map((skill) => (
                  <Skill>• {skill}</Skill>
                ))}
              </ItemWrapper>
            </Skills> */}
          </>
        )}
      </Description>
    </Card>
  );
};

export default EducationCard;
