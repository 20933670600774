import React from "react";
import {
  IntroContainer,
  IntroInnerContainer,
  Title,
  IntroLeftContainer,
  IntroRightContainer,
  SubTitle,
  RoleText,
  ResumeButton,
} from "./IntroStyleComponent";
import { Bio } from "../../data/constants";

const Intro = () => {
  return (
    <div id="about">
      <IntroContainer>
        <IntroInnerContainer>
          <IntroLeftContainer>
            <Title>{Bio.name}</Title>
            <RoleText>{Bio.roles[0]}</RoleText>
            <ResumeButton href={Bio.resume} target="display">
              Check Resume
            </ResumeButton>
          </IntroLeftContainer>
          <IntroRightContainer>
            <SubTitle>
              {Bio.description.split("\n").map((text) => {
                return (
                  <p>
                    <span>{text}</span>
                  </p>
                );
              })}
            </SubTitle>
          </IntroRightContainer>
        </IntroInnerContainer>
      </IntroContainer>
    </div>
  );
};

export default Intro;
